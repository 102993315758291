$dark-base-color: #111;
$light-base-color: #fff;
$gray-base-color: whitesmoke;
$gray-dark-color: #BEBEBE;
$light-cream-color: global #f5f5f3;
$purple-base-color: #a86af7;
$purple-dark-color: #6e07f3;

body,
html {
  font-family: "Afacad Flux", sans-serif;
  color: $dark-base-color;
}

code {
  font-family: "Fira Code", monospace !important;
  font-size: 12px !important;
  font-weight: 300;
  max-width: 10rem;
}

section {
  min-height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: $light-cream-color;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a:hover {
  color: #18216d;
}

input,
textarea {
  border-radius: 4px;
  border: 0;
  background: rgb(241, 242, 243);
  transition: all 0.3s ease-in-out;
  outline: none;
  width: 100%;
  padding: 1rem 1.25rem;

  :focus-within {
    background: none;
    box-shadow: #2e186a 0px 0px 0px 1px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Afacad Flux", sans-serif;
  color: $dark-base-color;
  font-size: 56px;
  line-height: 1.18;

  @media only screen and (max-width: 890px) {
    font-size: 47px;
  }

  @media only screen and (max-width: 414px) {
    font-size: 32px;
  }
}

p {
  color: $dark-base-color;
  font-size: 21px;
  line-height: 1.41;
}

h1 {
  font-weight: 600;
}

a {
  text-decoration: none;
  outline: none;
  color: #2e186a;

  :hover {
    color: #2e186a;
  }
}

*:focus {
  outline: none;
}

.about-block-image svg {
  text-align: center;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 1.5rem;
}

body.dark {
  color: $light-base-color;
  background: $dark-base-color;
  transition: all 0.3s ease-in-out;

  a {
    color: $light-base-color;
    transition: color 0.3s ease-in-out;

    :hover {
      color: $purple-dark-color;
    }
  }

  li {
    color: $light-base-color;
    transition: color 0.3s ease-in-out;
  }

  input,
  textarea {
    background: $gray-dark-color;
    color: $light-base-color;
    transition: all 0.3s ease-in-out;

    :focus-within {
      box-shadow: $purple-dark-color 0px 0px 0px 1px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $light-base-color;
    transition: color 0.3s ease-in-out;
  }

  p {
    color: $light-base-color;
    transition: color 0.3s ease-in-out;
  }

  section {
    background: $dark-base-color;
    transition: background 0.3s ease-in-out;
  }

  .ant-card-body {
    background: $dark-base-color;
    transition: background 0.3s ease-in-out;
  }

  .card {
    background: none;
    color: $light-base-color;
    transition: all 0.3s ease-in-out;

    .ant-card {
      background: $dark-base-color;
      transition: background 0.3s ease-in-out;
    }

    .ant-card-meta-title {
      color: $light-base-color;
      transition: color 0.3s ease-in-out;
    }
    .ant-card-meta-description {
      color: $light-base-color;
      transition: color 0.3s ease-in-out;
    }

    a {
      color: $purple-base-color;
      transition: color 0.3s ease-in-out;

      :hover {
        color: $purple-dark-color;
      }
    }
  }
}