@import "../../styles/styles";

.styled-button {
  background: $purple-dark-color;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  width: 100%;
  border: none;
  border-radius: 100px;
  padding: 9px 0px;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(23, 31, 114, 0.2) 0px 16px 30px;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: $purple-base-color;
  }
}
