@import "../../styles/styles";

.section {
  min-height: auto;
  background: $gray-base-color;
  border-top: 1px solid $gray-dark-color;

  .footer-logo {
    max-width: 100px;
    max-height: 100px;

    &:hover {
      filter: saturate(20);
    }
  }

  .footer-container {
    a {
      color: $light-base-color;
      &:hover,
      &:active,
      &:focus {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        transition: all 0.1s ease-in-out;
      }
    }

    @media screen and (max-width: 769px) {
      width: auto;
    }
  }
}
